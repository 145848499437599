@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	@variants dark {
		.invert {
			filter: invert(1);
		}
	}
}